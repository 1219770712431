import Glide from '@glidejs/glide';

window.addEventListener('load', () => {
  var glideEle = document.querySelector('#options-start-at');

  if (glideEle) {
    const slider = new Glide('#options-start-at', {
      type: 'slider',
      focusAt: 0,
      startAt: 0,
      perView: 3,
      slidesToScroll: 1,
      keyboard: true,
      gap: 20,
      bound: true,
      loop: true,
      rewind: true,
      breakpoints: {
        1024: {
          perView: 2,
        },
        600: {
          perView: 1,
        },
      },
    });
    slider.mount();
  }
});
