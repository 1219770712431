/* eslint-disable no-restricted-syntax */
import Glide from '@glidejs/glide';

import { Controller } from 'stimulus';
import Swal from 'sweetalert2';

export default class extends Controller {
  static values = {
    eventId: String,
    doctorId: String,
  };

  connect() {
    if (document.querySelector('.telehealth_providers') != null) {
      const telehealthProviders = new Glide('.telehealth_providers', {
        type: 'slider',
        focusAt: 0,
        startAt: 0,
        perView: 2,
        slidesToScroll: 1,
        keyboard: true,
        gap: 10,
        bound: true,
        loop: true,
        rewind: true,
        breakpoints: {
          1024: {
            perView: 2,
            gap: 8,
          },
          600: {
            perView: 1,
            gap: 2,
          },
        },
      });
      telehealthProviders.mount();
    }
    const telehealthProvidersSlot = new Glide('.telehealth_providers_slot', {
      type: 'slider',
      focusAt: 0,
      startAt: 0,
      perView: 1,
      slidesToScroll: 1,
      keyboard: true,
      gap: 10,
      bound: true,
      loop: true,
      rewind: true,
    });
    telehealthProvidersSlot.mount();
    this.setHeightSlots();
  }

  setHeightSlots() {
    var slots = document.querySelectorAll('.slots');
    for (var i = 0; i < slots.length; i++) {
      if (slots[i].clientHeight > 90) {
        slots[i].style.height = '90px';
        slots[i].style.overflow = 'hidden';
        slots[i].nextElementSibling.classList.remove('hidden');
        slots[i].nextElementSibling.classList.add('flex');
      }
    }
  }

  showMoreSlots(event) {
    var showmore = event.currentTarget;
    showmore.classList.remove('flex');
    showmore.classList.add('hidden');
    showmore.previousElementSibling.style.height = 'auto';
    showmore.previousElementSibling.style.overflow = 'auto';
    showmore.nextElementSibling.classList.add('flex');
    showmore.nextElementSibling.classList.remove('hidden');
  }

  showLessSlots(event) {
    var showless = event.currentTarget;
    showless.classList.remove('flex');
    showless.classList.add('hidden');
    showless.previousElementSibling.previousElementSibling.style.height =
      '90px';
    showless.previousElementSibling.previousElementSibling.style.overflow =
      'hidden';
    showless.previousElementSibling.classList.add('flex');
    showless.previousElementSibling.classList.remove('hidden');
  }

  // Function to handle checkbox change event
  addonCheck(event) {
    const checkbox = event.target;
    const serviceId = checkbox.value;

    const isChecked = checkbox.checked;
    const action = isChecked ? 'ADD' : 'REMOVE';

    // Get the value of the token
    const tokenInput = document.querySelector("input[name='token']");
    const orderToken = tokenInput.value;

    fetch(
      `/telehealth-registrations/additional_services/${encodeURIComponent(
        orderToken
      )}/${serviceId}/${action}`
    )
      .then((response) => response.json())
      .then((data) => {
        document.querySelector('#cart').innerHTML = data.partial_html;
        if (parseInt(data.available_slots, 10) == 1 && isChecked) {
          Swal.fire(
            'No continous slots are available for this additional service'
          );
          checkbox.checked = false;
        }
      })
      .catch((error) => {
        // console.error('Error fetching data:', error);
      });
  }

  feedbackpopupclose() {
    var popups = document.querySelectorAll('.popup_container');
    for (var i = 0; i < popups.length; i++) {
      popups[i].classList.add('hidden');
    }
  }

  showAvailabilities() {
    const eventId = this.eventIdValue;
    const doctorId = this.doctorIdValue;

    this.fetchAvailabilities(
      `/telehealth-search/availabilities?event_id=${eventId}&doctor_id=${doctorId}`,
      '#availabilities',
      eventId,
      doctorId
    );
  }

  loadAvailabileSlots(eventId, doctorId) {
    this.fetchAvailabilities(
      `/telehealth-search/availabilities?event_id=${eventId}&doctor_id=${doctorId}&type=slots`,
      '#telehealth_providers_popup'
    );
  }

  fetchAvailabilities(url, targetSelector, eventId, doctorId) {
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        document.querySelector(targetSelector).innerHTML = data.partial_html;

        if (targetSelector === '#telehealth_providers_popup') {
          const telehealthProviders3 = new Glide(
            '#telehealth_providers_popup',
            {
              type: 'slider',
              focusAt: 0,
              startAt: 0,
              perView: 5,
              slidesToScroll: 1,
              keyboard: true,
              gap: 10,
              bound: true,
              loop: true,
              rewind: true,
              breakpoints: {
                1024: {
                  perView: 4,
                  gap: 8,
                },
                600: {
                  perView: 3,
                  gap: 2,
                },
              },
            }
          );
          telehealthProviders3.mount();
        }

        if (eventId && doctorId) {
          this.loadAvailabileSlots(eventId, doctorId);
        }
      })
      .catch((error) => {
        // console.error('Error fetching data:', error);
      });
  }
}
