import { Controller } from 'stimulus';
import Swal from 'sweetalert2';

export default class extends Controller {
  profile(e) {
    const fddId = e.currentTarget.getAttribute('data-fddid');

    Swal.fire({
      title: '',
      html: this.shareForm('', ''),
      confirmButtonText: 'Submit',
      showCancelButton: true,
      cancelButtonColor: '##e2e8f0',
      confirmButtonColor: '#e7ab00',
      focusConfirm: false,
      showConfirmButton: false,
    });
  }

  shareForm(baseURL, fddId) {
    return `
      ${this.shareHtml(baseURL, fddId)}
    `;
  }

  shareHtml(baseURL, fddId) {
    return `<div class="a2a_kit a2a_kit_size_32 a2a_default_style" data-a2a-url="${baseURL}/doctor/profile/${fddId}">
    <button class="button" data-sharer="somesharer" data-width="800" data-height="600" data-title="Checkout Sharer.js!" data-url="https://ellisonleao.github.io/sharer.js/">Share!</button>

    </div>
    `;
  }
}
