import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'readFull',
    'readMoreBackground',
    'readMoreIcon',
    'readCFull',
    'readCMoreBackground',
    'readCMoreIcon',
    'readConditionFull',
    'readConditionMoreBackground',
    'readConditionMoreIcon',
    'readTreatmentFull',
    'readTreatmentMoreBackground',
    'readTreatmentMoreIcon',
  ];

  connect() {
    this.viewlimore();
  }

  readMore(event) {
    this.readFullTarget.removeAttribute('style');
    this.readMoreBackgroundTarget.removeAttribute('style');
    this.readMoreIconTarget.innerHTML =
      'Less <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-up" class="w-4 h-4 inline-block" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style="padding-left: 5px;"><path fill="currentColor" d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z"></path></svg>';
    this.readMoreIconTarget.setAttribute(
      'data-action',
      'click->doctor#readLess'
    );
  }

  readLess(event) {
    this.readFullTarget.setAttribute(
      'style',
      'max-height: 120px; position: relative; overflow: hidden;'
    );
    this.readMoreBackgroundTarget.setAttribute(
      'style',
      'position: absolute; bottom: 0px; left: 0px; width: 100%; padding: 30px 0px; background-image: linear-gradient(rgba(255, 255, 255, 0), white);'
    );
    this.readMoreIconTarget.innerHTML =
      'Read More <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-down" class="w-4 h-4 inline-block" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style="padding-left: 5px;"><path fill="currentColor" d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path></svg>';
    this.readMoreIconTarget.setAttribute(
      'data-action',
      'click->doctor#readMore'
    );
  }

  readCMore(event) {
    this.readCFullTarget.removeAttribute('style');
    this.readCMoreBackgroundTarget.removeAttribute('style');
    this.readCMoreIconTarget.innerHTML =
      'Less <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-up" class="w-4 h-4 inline-block" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style="padding-left: 5px;"><path fill="currentColor" d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z"></path></svg>';
    this.readCMoreIconTarget.setAttribute(
      'data-action',
      'click->doctor#readCLess'
    );
  }

  readCLess(event) {
    this.readCFullTarget.setAttribute(
      'style',
      'max-height: 120px; position: relative; overflow: hidden;'
    );
    this.readCMoreBackgroundTarget.setAttribute(
      'style',
      'position: absolute; bottom: 0px; left: 0px; width: 100%; padding: 30px 0px; background-image: linear-gradient(rgba(255, 255, 255, 0), white);'
    );
    this.readCMoreIconTarget.innerHTML =
      'Read More <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-down" class="w-4 h-4 inline-block" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style="padding-left: 5px;"><path fill="currentColor" d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path></svg>';
    this.readCMoreIconTarget.setAttribute(
      'data-action',
      'click->doctor#readCMore'
    );
  }

  readConditionMore(event) {
    this.readConditionFullTarget.removeAttribute('style');
    this.readConditionMoreBackgroundTarget.removeAttribute('style');
    this.readConditionMoreIconTarget.innerHTML =
      'Less <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-up" class="w-4 h-4 inline-block" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style="padding-left: 5px;"><path fill="currentColor" d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z"></path></svg>';
    this.readConditionMoreIconTarget.setAttribute(
      'data-action',
      'click->doctor#readConditionLess'
    );
  }

  readConditionLess(event) {
    this.readConditionFullTarget.setAttribute(
      'style',
      'max-height: 120px; position: relative; overflow: hidden;'
    );
    this.readConditionMoreBackgroundTarget.setAttribute(
      'style',
      'position: absolute; bottom: 0px; left: 0px; width: 100%; padding: 30px 0px; background-image: linear-gradient(rgba(255, 255, 255, 0), white);'
    );
    this.readConditionMoreIconTarget.innerHTML =
      'Read More <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-down" class="w-4 h-4 inline-block" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style="padding-left: 5px;"><path fill="currentColor" d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path></svg>';
    this.readConditionMoreIconTarget.setAttribute(
      'data-action',
      'click->doctor#readConditionMore'
    );
  }

  readTreatmentMore(event) {
    this.readTreatmentFullTarget.removeAttribute('style');
    this.readTreatmentMoreBackgroundTarget.removeAttribute('style');
    this.readTreatmentMoreIconTarget.innerHTML =
      'Less <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-up" class="w-4 h-4 inline-block" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style="padding-left: 5px;"><path fill="currentColor" d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z"></path></svg>';
    this.readTreatmentMoreIconTarget.setAttribute(
      'data-action',
      'click->doctor#readTreatmentLess'
    );
  }

  readTreatmentLess(event) {
    this.readTreatmentFullTarget.setAttribute(
      'style',
      'max-height: 120px; position: relative; overflow: hidden;'
    );
    this.readTreatmentMoreBackgroundTarget.setAttribute(
      'style',
      'position: absolute; bottom: 0px; left: 0px; width: 100%; padding: 30px 0px; background-image: linear-gradient(rgba(255, 255, 255, 0), white);'
    );
    this.readTreatmentMoreIconTarget.innerHTML =
      'Read More <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-down" class="w-4 h-4 inline-block" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style="padding-left: 5px;"><path fill="currentColor" d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path></svg>';
    this.readTreatmentMoreIconTarget.setAttribute(
      'data-action',
      'click->doctor#readTreatmentMore'
    );
  }

  moreItems(event) {
    event.target.nextElementSibling.classList.remove('hidden');
    event.target.classList.add('hidden');
    var listParent = event.target.getAttribute('data-parent-id');
    var hiddenList = document.querySelectorAll(
      `#${listParent} [data-item="listitem"]`
    );
    for (var i = 11; i < hiddenList.length; i++) {
      hiddenList[i].classList.remove('hidden');
    }
  }

  lessItems(event) {
    event.target.previousElementSibling.classList.remove('hidden');
    event.target.classList.add('hidden');
    var listParent = event.target.getAttribute('data-parent-id');
    var hiddenList = document.querySelectorAll(
      `#${listParent} [data-item="listitem"]`
    );
    for (var i = 11; i < hiddenList.length; i++) {
      hiddenList[i].classList.add('hidden');
    }
  }

  viewlimore() {
    var reviewItemContainer = document.querySelectorAll('.reviewItemContainer');
    var moreLink = document.querySelectorAll('.moreLink');
    var lessLink = document.querySelectorAll('.lesslinkV1');
    // more button and text hidden for review card
    for (let a = 0; a < reviewItemContainer.length; a++) {
      if (
        reviewItemContainer[a].querySelectorAll('.reviewText')[0].clientHeight >
        67
      ) {
        reviewItemContainer[a]
          .querySelectorAll('.reviewText')[0]
          .classList.add('ellipsis', 'ellipsis-3');
        reviewItemContainer[a]
          .querySelectorAll('.moreLink')[0]
          .classList.remove('hidden');
      } else if (
        reviewItemContainer[a].querySelectorAll('.reviewLiItem')[0]
          .clientHeight > 60
      ) {
        reviewItemContainer[a]
          .querySelectorAll('.reviewLiItem')[0]
          .classList.add('hidden');
        reviewItemContainer[a]
          .querySelectorAll('.moreLink')[0]
          .classList.remove('hidden');
      }
    }

    // more content
    for (let k = 0; k < moreLink.length; k++) {
      moreLink[k].onclick = function () {
        this.parentNode
          .querySelectorAll('.reviewText')[0]
          .classList.remove('ellipsis', 'ellipsis-3');
        this.parentNode
          .querySelectorAll('.reviewLiItem')[0]
          .classList.remove('hidden');
        moreLink[k].parentNode
          .querySelectorAll('.lesslinkV1')[0]
          .classList.remove('hidden');
        this.classList.add('hidden');
      };
    }
    // less contentStyle
    for (let b = 0; b < lessLink.length; b++) {
      lessLink[b].onclick = function () {
        this.parentNode
          .querySelectorAll('.reviewText')[0]
          .classList.add('ellipsis', 'ellipsis-3');
        this.parentNode
          .querySelectorAll('.reviewLiItem')[0]
          .classList.add('hidden');
        this.parentNode
          .querySelectorAll('.moreLink')[0]
          .classList.remove('hidden');
        this.classList.add('hidden');
      };
    }
    var reviewSummary = document.querySelector('.reviewSummary');
    if (reviewSummary.clientHeight > 160) {
      reviewSummary.classList.add('li-hidden');
      reviewSummary.parentNode
        .querySelector('.limorelink')
        .classList.remove('hidden');
    }

    var lesslilink = document.querySelectorAll('.lilesslink');
    for (let a = 0; a < lesslilink.length; a++) {
      lesslilink[a].onclick = function () {
        reviewSummary.classList.add('li-hidden');
        lesslilink[a].parentNode
          .querySelector('.limorelink')
          .classList.remove('hidden');
        lesslilink[a].classList.add('hidden');
      };
    }
    var morelilink = document.querySelectorAll('.limorelink');
    for (let a = 0; a < morelilink.length; a++) {
      morelilink[a].onclick = function () {
        reviewSummary.classList.remove('li-hidden');
        morelilink[a].parentNode
          .querySelector('.lilesslink')
          .classList.remove('hidden');
        morelilink[a].classList.add('hidden');
      };
    }
  }
}
