/* eslint-disable dot-notation */
/* eslint-disable prefer-destructuring */
import { Controller } from 'stimulus';
import { ajax } from 'jquery';
import ParamRedirect, {
  hideLoading,
  ParamUrl,
  showLoading,
  URIPush,
} from './param_redirect';

export default class UrgentCareFilterController extends Controller {
  static targets = ['form', 'near', 'clear', 'searchUrl'];

  connect() {
    this.timeout = null;
    this.formTarget.addEventListener('change', (_e) => {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.buildResults();
      }, 1000);
    });

    this.clearTarget.addEventListener('click', (ev) => {
      ev.preventDefault();
      window.history.pushState({ clear_filters: true }, 'clear filters');
      this.resetForm(this.formTarget);
      this.buildResults();
      return false;
    });
  }

  formValue(form) {
    const data = new FormData(form);
    const keys = Array.from(new Set(data.keys()));
    const formHash = {};
    keys.forEach((key) => {
      const val = data.getAll(key);
      if (val !== undefined && val !== null && val !== '') {
        formHash[key] = val.length > 1 ? val : val[0];
      }
      if (key === 'near' && val && val !== '') {
        this.nearTarget.value = null;
      }
    });

    return formHash;
  }

  resetForm(form) {
    const { elements } = form;
    form.reset();
    for (let i = 0; i < elements.length; i++) {
      const fieldType = elements[i].type.toLowerCase();
      switch (fieldType) {
        case 'text':
        case 'password':
        case 'textarea':
        case 'hidden':
          elements[i].value = '';
          break;

        case 'radio':
        case 'checkbox':
          if (elements[i].checked) {
            elements[i].checked = false;
          }
          break;

        case 'select-one':
        case 'select-multi':
          elements[i].selectedIndex = -1;
          break;

        default:
          break;
      }
    }
  }

  buildResults() {
    const searchUrl = this.data.get('searchUrl');
    document.querySelector('#search-side-bar').classList.add('hidden');
    const formValues = this.formValue(this.formTarget);
    this.getResults().then(
      (data) => {
        hideLoading();
        const container = document.querySelector('#result-container');
        const pagination = document.querySelector('#pagination-container');
        const searchResultsCount = document.querySelector(
          '#search_results_count'
        );
        const breadCrumbContainer = document.querySelector(
          '#bread_crumb_container'
        );

        if (!container) {
          return null;
        }
        container.innerHTML = data.results;
        pagination.innerHTML = data.pagination;
        searchResultsCount.innerHTML = data.total_records;
        breadCrumbContainer.innerHTML = data.bread_crumb_html;
        document.getElementById('searchTerm').value = data.speciality_name;
        document.getElementById('near-location').innerHTML = data.h1_title;

        window.scrollTo(0, 0);

        URIPush({
          changeParams: formValues,
          route: searchUrl,
          removeParams: ['page', ...this.removedFilters(formValues)],
        });

        return null;
      },
      (rej) => {
        hideLoading();
      }
    );
  }

  removedFilters(formValue) {
    // REMEMBER: make sure to change this when doctor filters are updated
    const allFields = [
      'specialty',
      'practice_style',
      'rating',
      'holistic_medicine',
      'experience',
      'telehealth',
      'life_style_medicine',
      'price',
      'is_specialized_search',
    ];
    const keys = Object.keys(formValue);

    return allFields.filter((a) => keys.indexOf(a) < 0);
  }

  getResults() {
    const searchUrl = this.data.get('searchUrl');
    showLoading();
    return ajax({
      url: ParamUrl({
        changeParams: this.formValue(this.formTarget),
        removeParams: ['page'],
        route: `${searchUrl}.json`,
      }),
    });
  }
}
