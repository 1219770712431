import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    const makeLinkSmooth = () => {
      const tabs = document.querySelectorAll('.smoothscroll');
      tabs.forEach((tab) => {
        tab.addEventListener('click', (e) => {
          tabs.forEach((intab) => {
            intab.classList.remove('border-b-4');
          });
          tab.classList.add('border-b-4');
          e.preventDefault();
          document.querySelector(tab.hash).scrollIntoView({
            behavior: 'smooth',
          });
        });
      });
    };

    // function call for makeLinkSmooth
    makeLinkSmooth();
  }
}
