import { Controller } from 'stimulus';
import { customIcon } from './map_pin_generator';

const { GMaps } = window;

export default class extends Controller {
  connect() {
    const cords = this.locationLists();
    this.currentLocation = { ...this.locationLatLng(cords[0]) };
    this.map = new GMaps({
      div: '.clinic-map',
      zoom: 12,
      lat: this.currentLocation.lat,
      lng: this.currentLocation.lng,
      zoomControl: false,
      fullscreenControl: false,
      mapTypeControl: false,
    });
    this.addPin();
  }

  addPin() {
    const icon = customIcon({ multiple: true, color: '#e7ab00' });
    const locationLists = this.locationLists();
    locationLists.forEach((location) => {
      this.map.addMarker({
        ...this.locationLatLng(location),
        icon,
        label: '•',
      });
    });
  }

  locationLatLng(coords) {
    return { lat: coords[0], lng: coords[1] };
  }

  locationLists() {
    const locationLists = JSON.parse(this.data.get('location'));
    return locationLists;
  }

  defaultLocation() {
    const coord = this.data
      .get('location')
      .split(',')
      .map((a) => parseFloat(a));

    return { lat: 40.712772, lng: -74.006058 };
  }
}
