import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    const listcotainer = document.querySelectorAll('.listcotainer');
    for (let i = 0; i < listcotainer.length; i++) {
      const listitems = listcotainer[i].querySelectorAll('.listitems li');
      const more = listcotainer[i].querySelector('.showmore');
      const less = listcotainer[i].querySelector('.showless');
      for (let a = 0; a < listitems.length; a++) {
        if (a < 1) {
          listitems[a].style.display = 'block';
        } else {
          listitems[a].style.display = 'none';
        }
      }
    }

    const more = document.querySelectorAll('.showmore');
    const less = document.querySelectorAll('.showless');
    for (let m = 0; m < more.length; m++) {
      more[m].onclick = function () {
        this.style.display = 'none';
        this.nextElementSibling.style.display = 'block';
        const liItems = this.previousElementSibling.children;
        for (let b = 0; b < liItems.length; b++) {
          liItems[b].style.display = 'block';
        }
      };
    }

    for (let l = 0; l < less.length; l++) {
      less[l].onclick = function () {
        this.style.display = 'none';
        this.previousElementSibling.style.display = 'block';
        const liItems =
          this.previousElementSibling.previousElementSibling.children;
        for (let c = 0; c < liItems.length; c++) {
          if (c >= 1) {
            liItems[c].style.display = 'none';
          }
        }
      };
    }
  }
}
