/* eslint-disable dot-notation */
/* eslint-disable no-restricted-syntax */
import { Controller } from 'stimulus';

const claimProfileInputFields = [
  'email',
  'password',
  'password_confirmation',
  'document',
];
export default class extends Controller {
  static targets = [
    'search',
    'searchTermType',
    'searchTermValue',
    'email',
    'password',
    'confirmPassword',
    'documentProof',
  ];

  connect() {
    const that = this;
    const inputElement = document.getElementById('user_email');
    if (inputElement) {
      inputElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      inputElement.focus();
    }
    document.addEventListener('autocomplete.change', function (e) {
      const { value, textValue } = e.detail;

      const searchTermType = value.split('_')['1'];
      document
        .getElementById('searchTerm')
        .setAttribute('name', searchTermType);
      that.searchTermTypeTarget.value = searchTermType;
      that.searchTermValueTarget.value = value;

      that.claimProfile(value);
    });
  }

  claimProfile(value) {
    const formData = new FormData();
    formData.append('search_term', value);
    document.getElementById('profile_file_upload').classList.add('hidden');

    fetch(`/doctor_card_claim_free_profile`, {
      body: formData,
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': this.getMetaValue('csrf-token'),
      },
    })
      .then((response) => response.text())
      .then((html) => {
        console.log(html);
        document.getElementById('doctor_profile_card').innerHTML = html;
        document
          .getElementById('profile_file_upload')
          .classList.remove('hidden');
      });
  }

  registerUser() {
    const email = this.emailTarget.value;
    const password = this.passwordTarget.value;
    const confirmPassword = this.confirmPasswordTarget.value;
    const documentProof = this.documentProofTarget.value;
    const searchTerm = this.searchTermValueTarget.value;

    const formData = new FormData();
    formData.append('user[email]', email);
    formData.append('user[password]', password);
    formData.append('user[confirmPassword]', confirmPassword);
    formData.append('document_proof', documentProof);
    formData.append('search_term', searchTerm);

    fetch(`/register_new_provider`, {
      body: formData,
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': this.getMetaValue('csrf-token'),
      },
    })
      .then((response) => response.text())
      .then((html) => {
        console.log(html);
      });
  }

  success(event) {
    const [data, status, xhr] = event.detail;
    document.getElementById('claim_free_profile').innerHTML = xhr.response;
  }

  errorHandler(event) {
    const [data, status, xhr] = event.detail;
    const errors = JSON.parse(xhr.response);

    this.showErrors(errors['message'] || {});
  }

  showErrors(errors) {
    for (const input of claimProfileInputFields) {
      if (errors[input]) {
        document.getElementById(`error_${input}`).textContent = errors[input];
      } else {
        document.getElementById(`error_${input}`).textContent = '';
      }
    }
  }

  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`);
    return element.getAttribute('content');
  }

  // autocompletejs() {
  //   console.log('connected');
  //   var searchInput = document.querySelector('.claim_profie');
  //   document.querySelector('#doctor_id').onchange = function () {
  //     document.querySelector('#profileForm').classList.add('hidden');
  //     document.querySelector('#doctor_profile').classList.remove('hidden');
  //   };
  //   document.querySelector('#profile_cancel').onclick = function () {
  //     document.querySelector('#profileForm').classList.remove('hidden');
  //     document.querySelector('#doctor_profile').classList.add('hidden');
  //     document.querySelector('.claim_profie').value = '';
  //     document.querySelector('#doctor_id').value = '';
  //   };
  // }
}
