import { Controller } from 'stimulus';
import { customIcon } from './map_pin_generator';

const { GMaps } = window;

export default class extends Controller {
  static targets = ['specialty', 'clinicSlug', 'city'];

  connect() {}

  filter(event) {
    let specialty = '';
    const clinicSlug = this.clinicSlugTarget.value;
    if (
      document.querySelector(
        "input[type='radio'][name=filter_specialty_name]:checked"
      ) !== null
    ) {
      specialty = document.querySelector(
        "input[type='radio'][name=filter_specialty_name]:checked"
      ).value;
    }
    const city = this.cityTarget.value;

    const formData = new FormData();
    formData.append('specialty', specialty);
    formData.append('clinic_slug', clinicSlug);
    formData.append('city', city);

    const url = '/clinic/filter';

    Rails.ajax({
      type: 'POST',
      url: url,
      data: formData,
      dataType: 'json',
      success: (data) => {
        document.getElementById('doctors-list').innerHTML = data.doctors;
        document.getElementById('clinic-doctor-show-more').innerHTML = '';
        if (specialty != '' || city != '') {
          document.getElementById('clearSearch').classList.remove('hidden');
        }
        this.paginationTarget.innerHTML = '';
      },
    });
  }

  clearSearch(event) {
    const clinicSlug = this.clinicSlugTarget.value;
    const formData = new FormData();
    formData.append('clear', true);
    formData.append('clinic_slug', clinicSlug);

    const radio = document.querySelector(
      "input[type='radio'][name=filter_specialty_name]:checked"
    );
    if (radio !== null) {
      radio.checked = false;
    }
    document.querySelector('#locationList').value = '';

    const url = '/clinic/filter';

    Rails.ajax({
      type: 'POST',
      url: url,
      data: formData,
      dataType: 'json',
      success: (data) => {
        document.getElementById('doctors-list').innerHTML = data.doctors;
        document.getElementById('clinic-doctor-show-more').innerHTML = '';
        document.getElementById('clearSearch').classList.add('hidden');
        this.paginationTarget.innerHTML = '';
      },
    });
  }
}
