import Glide from '@glidejs/glide';

import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.glidejscfa();
  }

  glidejscfa() {
    window.addEventListener('load', () => {
      const slider0cfa = new Glide('#condition_glide', {
        type: 'slider',
        perView: 4,
        slidesToScroll: 1,
        gap: 10,
        breakpoints: {
          1024: {
            perView: 3,
            gap: 8,
          },
          600: {
            perView: 1,
            gap: 5,
          },
        },
      });
      slider0cfa.mount();
    });
  }
}
