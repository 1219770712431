import Glide from '@glidejs/glide';

import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.glidejshome();
  }

  glidejshome() {
    window.addEventListener('load', () => {
      if (document.querySelector('.glidedoctors')) {
        const slider3 = new Glide('.glidedoctors', {
          type: 'slider',
          focusAt: 0,
          startAt: 0,
          perView: 1,
          slidesToScroll: 1,
          keyboard: true,
          gap: 2,
          bound: true,
          loop: true,
          rewind: true,
        });
        slider3.mount();
      }

      if (document.querySelector('.claim_profile_doctors')) {
        const slider4 = new Glide('.claim_profile_doctors', {
          type: 'carousel',
          autoplay: 2500,
          focusAt: 0,
          startAt: 0,
          perView: 1,
          slidesToScroll: 1,
          keyboard: true,
          animationTimingFunc: 'ease-in-out',
          animationDuration: 950,
          gap: 20,
        });
        slider4.mount();
      }
    });
  }
}
