/* eslint-disable no-param-reassign */
/* eslint-disable new-cap */
/* eslint-disable no-shadow */
/* eslint-disable dot-notation */
/* eslint-disable no-restricted-syntax */
import consumer from './consumer';

document.addEventListener('turbolinks:load', () => {
  initializeConversationSubscription();
});

function initializeConversationSubscription() {
  const conversationId = fetchConversationId();
  if (!conversationId) return;

  const subscription = consumer.subscriptions.create(
    { channel: 'ConversationChannel', id: conversationId },
    {
      connected() {},
      disconnected() {},
      received(data) {
        handleReceivedMessage(data);
      },
    }
  );
}

function fetchConversationId() {
  const conversationElement = document.getElementById('conversation-id');
  return conversationElement
    ? conversationElement.getAttribute('data-conversation-id')
    : null;
}

function handleReceivedMessage(data) {
  const userId = fetchUserId();
  if (!userId) return;

  const messageHtml =
    userId === data.message.user_id ? data.send : data.recieved;
  appendMessageToContainer(messageHtml);
}

function fetchUserId() {
  const userElement = document.getElementById('user-id');
  return userElement ? Number(userElement.getAttribute('data-user-id')) : null;
}

function appendMessageToContainer(html) {
  const messageContainer = document.getElementById('messages');
  if (messageContainer) messageContainer.innerHTML += html;
  messageContainer.scrollTop = messageContainer.scrollHeight;
}
