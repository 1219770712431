/* eslint-disable prefer-destructuring */

import { Controller } from 'stimulus';

const places = require('places.js');

export default class extends Controller {
  static targets = ['field', 'near', 'search', 'lat', 'lang'];

  connect() {
    this.params = {};
    if (typeof google != 'undefined') {
      this.initPlaces();
    }
    const that = this;
  }

  initPlaces() {
    const options = {
      componentRestrictions: { country: ['us', 'pr', 'vi', 'gu', 'mp'] },
    };
    this.autocomplete = new google.maps.places.Autocomplete(
      this.fieldTarget,
      options
    );
    // this.autocomplete.bindTo('bounds', this.map)
    this.autocomplete.setFields([
      'address_components',
      'geometry',
      'icon',
      'name',
    ]);
    this.autocomplete.addListener(
      'place_changed',
      this.placeChanged.bind(this)
    );
  }

  placeChanged() {
    const place = this.autocomplete.getPlace();
    const name = place.name;
    const near = `${place.geometry.location.lat()},${place.geometry.location.lng()}`;

    const { value } = this.nameTarget;
    const paramHash = { near, place: name };
    if (value !== undefined && value !== null && value !== '') {
      this.setParams();
    }
    this.params = paramHash;
  }

  submitSearch(event) {
    event.preventDefault();

    const { host, hostname, href, origin, pathname, port, protocol, search } =
      window.location;

    let url = `${origin}${pathname}`;

    this.setParams();
    const queryString = new URLSearchParams(this.params || {}).toString();
    url = `${url}?${queryString}`;
    Turbolinks.visit(url);
  }

  setParams() {
    const placeName = this.fieldTarget.value;
    if (placeName) this.params.place = placeName;
  }

  convertToSlug(text) {
    return text
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '');
  }
}
