import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.alphabetView();
  }

  resAlphabetView(event) {
    document.querySelectorAll('.pc-letters').forEach((elem) => {
      elem.classList.add('hidden');
    });
    document.querySelectorAll('.mobile-pc-letters').forEach((elem) => {
      elem.classList.remove('border-b-4', 'border-doctor-yellow-500');
    });

    document
      .querySelector('#pc-all')
      .classList.remove('border-b-4', 'border-doctor-yellow-500', 'hidden');
    var { id } = event.target;
    document
      .querySelector(`#${id}`)
      .classList.add('border-b-4', 'border-doctor-yellow-500');
    document.querySelectorAll(`.${id}`).forEach((ele) => {
      ele.classList.remove('hidden');
    });
  }

  alphabetView() {
    var pcLinks = document.querySelectorAll('.pc-links a');
    var alphaval = [];
    var letterArr = [];
    for (var i = 0; i < pcLinks.length; i++) {
      alphaval[i] = pcLinks[i].textContent;
      letterArr[i] = alphaval[i].charAt(0);
    }
    letterArr = letterArr.filter(
      (item, index) => letterArr.indexOf(item) === index
    );
    letterArr = letterArr.filter(
      (s) => (s || !isNaN(s)) && String(s).length > 0
    );

    letterArr.forEach((el) => {
      document.querySelector(`#pc-${el}`).classList.remove('text-gray-400');
      document.querySelector(`#pc-${el}`).classList.add('cursor-pointer');
      document
        .querySelector(`#pc-${el}`)
        .classList.remove('cursor-not-allowed');
      for (var k = 0; k < pcLinks.length; k++) {
        if (pcLinks[k].text.startsWith(el)) {
          pcLinks[k].parentElement.classList.add(el);
        }
      }
      document.querySelector(`#pc-${el}`).onclick = () => {
        document.querySelectorAll('.pc-letters').forEach((e) => {
          e.classList.remove('border-b-4', 'border-doctor-yellow-500');
        });
        document
          .querySelector(`#pc-${el}`)
          .classList.add('border-b-4', 'border-doctor-yellow-500');
        for (var a = 0; a < pcLinks.length; a++) {
          if (pcLinks[a].text.startsWith(el)) {
            pcLinks[a].parentElement.classList.remove('hidden');
            pcLinks[a].parentElement.classList.add('inline');
          } else {
            pcLinks[a].parentElement.classList.add('hidden');
            pcLinks[a].parentElement.classList.remove('inline');
          }
        }
      };
    });
    document.querySelector('#pc-all').onclick = () => {
      document.querySelectorAll('.pc-letters').forEach((e) => {
        e.classList.remove('border-b-4', 'border-doctor-yellow-500');
        e.classList.add('lg:block', 'hidden');
      });
      document.querySelectorAll('.mobile-pc-letters').forEach((elem) => {
        elem.classList.remove('border-b-4', 'border-doctor-yellow-500');
      });
      document
        .querySelector('#pc-all')
        .classList.add('border-b-4', 'border-doctor-yellow-500');
      document.querySelector('#pc-all').classList.remove('hidden');
      pcLinks.forEach((el) => {
        el.parentElement.classList.remove('hidden');
        el.parentElement.classList.add('inline');
      });
    };
  }
}
