/* eslint-disable prefer-destructuring */

import { Controller } from 'stimulus';

export default class extends Controller {
  loadNext() {
    this.loadPartial('next');
  }

  loadPrevious() {
    this.loadPartial('previous');
  }

  calculateNewDate(date, direction) {
    const currentDate = new Date(date);
    const offset = direction === 'next' ? 1 : -1;
    const newDate = new Date(
      currentDate.getTime() + offset * 7 * 24 * 60 * 60 * 1000
    );
    return newDate.toISOString().slice(0, 10);
  }

  loadPartial(direction) {
    const activeSlide = document.querySelector(
      '.glide__slide.glide__slide--active'
    );
    const firstDate = activeSlide.dataset.firstDate;
    const lastDate = activeSlide.dataset.lastDate;
    const id = activeSlide.dataset.id;
    const event = activeSlide.dataset.event;

    const newFirstDate = this.calculateNewDate(firstDate, direction);
    const newLastDate = this.calculateNewDate(lastDate, direction);

    fetch(
      `/telehealth-provider/timeslots?first_date=${newFirstDate}&last_date=${newLastDate}&id=${id}&event_id=${event}`
    )
      .then((response) => response.json())
      .then((data) => {
        // Render the fetched partial data
        this.element.innerHTML = data.partial_html;
      })
      .catch((error) => {
        // console.error('Error fetching data:', error);
      });
  }
}
