import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['errors', 'success'];

  connect() {
    this.checkFormValidity();
  }

  success(event) {
    const [data, status, xhr] = event.detail;
    this.successTarget.innerHTML = data.message;
    this.errorsTarget.innerHTML = '';
  }

  errorHandler(event) {
    const [data, status, xhr] = event.detail;
    this.errorsTarget.innerHTML = xhr.response;
  }

  checkFormValidity() {
    const form = this.element.querySelector('form');
    const submitButton = form.querySelector("button[type='submit']");
    const validateInputs = () => {
      let isValid = true;
      const checkedRadios = {};

      form.querySelectorAll("[data-validate='true']").forEach((input) => {
        if (input.type === 'checkbox' || input.type === 'radio') {
          if (input.type === 'checkbox') {
            if (!input.checked) {
              isValid = false;
            }
          } else {
            // radio button
            const radioGroupName = input.name;
            if (!checkedRadios[radioGroupName]) {
              checkedRadios[radioGroupName] = false;
            }
            checkedRadios[radioGroupName] =
              checkedRadios[radioGroupName] || input.checked;
          }
        } else if (input.value.trim() === '') {
          isValid = false;
        }
      });

      Object.values(checkedRadios).forEach((radioChecked) => {
        if (!radioChecked) {
          isValid = false;
        }
      });

      submitButton.disabled = !isValid;
      if (isValid) {
        submitButton.classList.remove('disabled-button');
      } else {
        submitButton.classList.add('disabled-button');
      }
    };

    form.querySelectorAll("[data-validate='true']").forEach((input) => {
      input.addEventListener('input', validateInputs);
    });

    validateInputs();
  }
}
