import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['entries', 'pagination', 'showmore'];

  loadMore() {
    const nextPage = this.paginationTarget.querySelector("a[rel='next']");
    if (nextPage == null) {
      this.showmoreTarget.innerHTML = '';
      return;
    }
    const url = nextPage.href;

    Rails.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      success: (data) => {
        this.entriesTarget.insertAdjacentHTML('beforeend', data.doctors);
        this.paginationTarget.innerHTML = data.pagination;
        if (data.doctors_count < 4) {
          this.showmoreTarget.innerHTML = '';
        }
      },
    });
  }
}
