import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['errors', 'success'];

  success(event) {
    document.querySelector('#feedbackformpopup form').classList.add('hidden');
    document.querySelector('#submitmessage').classList.remove('hidden');
    document.querySelectorAll('.feedback_option').forEach(function (element) {
      element.classList.add('hidden');
    });
  }

  errorHandler(event) {
    const [data, status, xhr] = event.detail;
    this.errorsTarget.innerHTML = xhr.response;
  }
}
