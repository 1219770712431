import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['category', 'vendorList', 'allCat'];

  filter(event) {
    const categories = this.getSelectedCategories();
    this.remoteFetch(categories);
  }

  allFilter(event) {
    const checkBox = document.getElementById('all-item');

    if (checkBox.checked == true) {
      this.remoteFetch('');
    } else {
      this.remoteFetch('');
    }
  }

  remoteFetch(categories) {
    const formData = new FormData();
    formData.append('partner[categories]', categories);

    fetch('strategic-partners/filter', {
      body: formData,
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': this.getMetaValue('csrf-token'),
      },
    })
      .then((response) => response.text())
      .then((html) => {
        // eslint-disable-next-line camelcase
        const htmlData = JSON.parse(html);
        this.vendorListTarget.innerHTML = htmlData.content;
      });
  }

  getSelectedCategories() {
    const categories = this.getCheckedCategories();
    // eslint-disable-next-line camelcase
    const selected_categories = [];
    categories.forEach(function (category) {
      selected_categories.push(category.value);
    });
    return selected_categories.join();
  }

  getCheckedCategories() {
    return this.categoryElements.filter((checkbox) => checkbox.checked);
  }

  get categoryElements() {
    return this.categoryTargets;
  }

  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`);
    return element.getAttribute('content');
  }
}
