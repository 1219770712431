import { Controller } from 'stimulus';
import Swal from 'sweetalert2';

export default class extends Controller {
  bookAppointment(e) {
    const fddId = e.currentTarget.getAttribute('data-fddid');
    const leadSource = e.currentTarget.getAttribute('data-lead-source');
    let gCaptchaAppointmentForm = '';
    Swal.fire({
      title: '',
      html: this.messageForm(),
      confirmButtonText: 'Submit',
      showCancelButton: true,
      cancelButtonColor: '##e2e8f0',
      confirmButtonColor: '#e7ab00',
      focusConfirm: false,
      didOpen: () => {
        gCaptchaAppointmentForm = grecaptcha.render('contact_recaptcha', {
          sitekey: '6LfNoucdAAAAABJdoFwhy8k6_ndQ7Stfqxxsbekb',
        });
      },
      preConfirm: () => {
        const name = Swal.getPopup().querySelector('#message_name').value;
        const email = Swal.getPopup().querySelector('#message_email').value;
        const message = Swal.getPopup().querySelector('#message_message').value;

        if (!name || !email || !message) {
          Swal.showValidationMessage(`All fields are required.`);
        }
        if (
          name &&
          email &&
          message &&
          grecaptcha.getResponse(gCaptchaAppointmentForm).length === 0
        ) {
          Swal.showValidationMessage(`Please verify that you're not a robot`);
        }

        return { name: name, email: email, message: message, fddId: fddId };
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const formData = new FormData();
        formData.append('form_submission[name]', result.value.name);
        formData.append('form_submission[email]', result.value.email);
        formData.append('form_submission[message]', result.value.message);
        formData.append('form_submission[fdd_id]', result.value.fddId);
        formData.append('form_submission[form_type]', 'book_appointment');
        formData.append('form_submission[lead_source]', leadSource);

        fetch('/form_submission', {
          body: formData,
          method: 'POST',
          credentials: 'same-origin',
          headers: {
            'X-CSRF-Token': this.getMetaValue('csrf-token'),
          },
        })
          .then((response) => response.text())
          .then((data) => {
            const responseData = JSON.parse(data);
            if (responseData.success == true) {
              let website = '';
              if (
                Object.prototype.hasOwnProperty.call(responseData, 'website')
              ) {
                website = responseData.website;
                window.open(`http://${website}`, '_blank');
              }
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Too many requests Error. Check back later!!',
              });
            }
          });
      }
    });
  }

  message(e) {
    const fddId = e.currentTarget.getAttribute('data-fddid');
    const leadSource = e.currentTarget.getAttribute('data-lead-source');

    let gCaptchaLeadForm = '';
    Swal.fire({
      title: '',
      html: this.messageForm(),
      confirmButtonText: 'Submit',
      showCancelButton: true,
      cancelButtonColor: '##e2e8f0',
      confirmButtonColor: '#e7ab00',
      focusConfirm: false,
      didOpen: () => {
        gCaptchaLeadForm = grecaptcha.render('contact_recaptcha', {
          sitekey: '6LfNoucdAAAAABJdoFwhy8k6_ndQ7Stfqxxsbekb',
        });
      },
      preConfirm: () => {
        const name = Swal.getPopup().querySelector('#message_name').value;
        const email = Swal.getPopup().querySelector('#message_email').value;
        const message = Swal.getPopup().querySelector('#message_message').value;

        if (!name || !email || !message) {
          Swal.showValidationMessage(`All fields are required.`);
        }

        if (
          name &&
          email &&
          message &&
          grecaptcha.getResponse(gCaptchaLeadForm).length === 0
        ) {
          Swal.showValidationMessage(`Please verify that you're not a robot`);
        }
        return { name: name, email: email, message: message, fddId: fddId };
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const formData = new FormData();
        formData.append('form_submission[name]', result.value.name);
        formData.append('form_submission[email]', result.value.email);
        formData.append('form_submission[message]', result.value.message);
        formData.append('form_submission[fdd_id]', result.value.fddId);
        formData.append('form_submission[form_type]', 'message');
        formData.append('form_submission[lead_source]', leadSource);

        fetch('/form_submission', {
          body: formData,
          method: 'POST',
          credentials: 'same-origin',
          headers: {
            'X-CSRF-Token': this.getMetaValue('csrf-token'),
          },
        })
          .then((response) => response.text())
          .then((data) => {
            const responseData = JSON.parse(data);
            if (responseData.success == true) {
              Swal.fire({
                icon: 'success',
                title: 'Thank you',
                text: "Thank you for the message. We will email you when we hear back from the provider's office.",
                confirmButtonText: 'OK',
                confirmButtonColor: '#191932',
              });
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Too many requests Error. Check back later!!',
              });
            }
          });
      }
    });
  }

  phone(e) {
    const fddId = e.currentTarget.getAttribute('data-fddid');
    const leadSource = e.currentTarget.getAttribute('data-lead-source');

    let gCaptchaLeadForm = '';
    Swal.fire({
      title: '',
      html: this.phoneForm(),
      confirmButtonText: 'View phone number',
      showCancelButton: true,
      cancelButtonColor: '##e2e8f0',
      confirmButtonColor: '#e7ab00',
      focusConfirm: false,
      didOpen: () => {
        gCaptchaLeadForm = grecaptcha.render('contact_recaptcha', {
          sitekey: '6LfNoucdAAAAABJdoFwhy8k6_ndQ7Stfqxxsbekb',
        });
      },
      preConfirm: () => {
        const name = Swal.getPopup().querySelector('#message_name').value;
        const email = Swal.getPopup().querySelector('#message_email').value;

        if (!name || !email) {
          Swal.showValidationMessage(`All fields are required.`);
        }

        if (
          name &&
          email &&
          grecaptcha.getResponse(gCaptchaLeadForm).length === 0
        ) {
          Swal.showValidationMessage(`Please verify that you're not a robot`);
        }

        return { name: name, email: email, fddId: fddId };
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const formData = new FormData();
        formData.append('form_submission[name]', result.value.name);
        formData.append('form_submission[email]', result.value.email);
        formData.append('form_submission[fdd_id]', result.value.fddId);
        formData.append('form_submission[form_type]', 'phone');
        formData.append('form_submission[lead_source]', leadSource);

        fetch('/form_submission', {
          body: formData,
          method: 'POST',
          credentials: 'same-origin',
          headers: {
            'X-CSRF-Token': this.getMetaValue('csrf-token'),
          },
        })
          .then((response) => response.text())
          .then((data) => {
            const responseData = JSON.parse(data);
            if (responseData.success == true) {
              let phoneNumber = '';
              if (Object.prototype.hasOwnProperty.call(responseData, 'phone')) {
                phoneNumber = responseData.phone;
              }

              Swal.fire({
                icon: 'success',
                title: 'Phone Number',
                text: phoneNumber,
                confirmButtonText: 'OK',
                confirmButtonColor: '#191932',
              });
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Too many requests Error. Check back later!!',
              });
            }
          });
      }
    });
  }

  website(e) {
    const fddId = e.currentTarget.getAttribute('data-fddid');
    const leadSource = e.currentTarget.getAttribute('data-lead-source');

    let gCaptchaLeadForm = '';
    Swal.fire({
      title: '',
      html: this.phoneForm(),
      confirmButtonText: 'Proceed to website',
      showCancelButton: true,
      cancelButtonColor: '##e2e8f0',
      confirmButtonColor: '#e7ab00',
      focusConfirm: false,
      didOpen: () => {
        gCaptchaLeadForm = grecaptcha.render('contact_recaptcha', {
          sitekey: '6LfNoucdAAAAABJdoFwhy8k6_ndQ7Stfqxxsbekb',
        });
      },
      preConfirm: () => {
        const name = Swal.getPopup().querySelector('#message_name').value;
        const email = Swal.getPopup().querySelector('#message_email').value;

        if (!name || !email) {
          Swal.showValidationMessage(`All fields are required.`);
        }

        if (
          name &&
          email &&
          grecaptcha.getResponse(gCaptchaLeadForm).length === 0
        ) {
          Swal.showValidationMessage(`Please verify that you're not a robot`);
        }

        return { name: name, email: email, fddId: fddId };
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const formData = new FormData();
        formData.append('form_submission[name]', result.value.name);
        formData.append('form_submission[email]', result.value.email);
        formData.append('form_submission[fdd_id]', result.value.fddId);
        formData.append('form_submission[form_type]', 'website');
        formData.append('form_submission[lead_source]', leadSource);

        fetch('/form_submission', {
          body: formData,
          method: 'POST',
          credentials: 'same-origin',
          headers: {
            'X-CSRF-Token': this.getMetaValue('csrf-token'),
          },
        })
          .then((response) => response.text())
          .then((data) => {
            const responseData = JSON.parse(data);
            if (responseData.success == true) {
              let website = '';
              if (
                Object.prototype.hasOwnProperty.call(responseData, 'website')
              ) {
                website = responseData.website;
                window.open(`http://${website}`, '_blank');
              }
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Too many requests Error. Check back later!!',
              });
            }
          });
      }
    });
  }

  messageForm() {
    return `<form class="pt-4 mb-4" data-type="html">
      ${this.nameFieldHtml()}
      ${this.emailFieldHtml()}
      ${this.messageFieldHtml()}
      ${this.reCaptcha()}
      ${this.policyHtml()}
    </form>`;
  }

  phoneForm() {
    return `<form class="pt-4 mb-4" data-type="html">
      ${this.nameFieldHtml()}
      ${this.emailFieldHtml()}
      ${this.reCaptcha()}
      ${this.policyHtml()}
    </form>`;
  }

  nameFieldHtml() {
    return `<div class="flex justify-center items-center w-full bg-white shadow mb-4 rounded-lg border border-gray-100">
      <label class="ml-3 mr-5">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" class="w-5 text-gray-600" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
        </svg>
      </label>
      <input class="my-3 w-full focus:outline-none bg-white" autocomplete="off" placeholder="Name" type="text" id="message_name">
    </div>`;
  }

  emailFieldHtml() {
    return `<div class="flex justify-center items-center w-full bg-white shadow mb-4 rounded-lg border border-gray-100">
      <label class="ml-3 mr-5">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" class="w-5 text-gray-600" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path>
        </svg>
      </label>
      <input class="my-3 w-full focus:outline-none bg-white" autocomplete="off" placeholder="Email" type="email" id="message_email">
    </div>`;
  }

  messageFieldHtml() {
    return `<div class="flex justify-center w-full bg-white shadow mb-4 rounded-lg border border-gray-100">
      <label class="ml-3 mr-5 mt-4">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" class="w-5 text-gray-600" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"></path>
        </svg>
      </label>
      <textarea class="my-3 w-full focus:outline-none bg-white" autocomplete="off" placeholder="Message" id="message_message"></textarea>
    </div>`;
  }

  policyHtml() {
    return `<div class="mx-auto text-center mt-8"></div>
    <div class="flex">
      <label >By clicking the submit button below, you agree to our <span><a class=" underline cursor-pointer" href="/terms-of-service" target="_blank"> Terms of Service </a></span> and <span><a class=" underline cursor-pointer" href="/privacy-policy" target="_blank">Privacy Policy</a></span>.
      </label>
    </div>`;
  }

  reCaptcha() {
    return `<div id="contact_recaptcha"></div>`;
  }

  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`);
    return element.getAttribute('content');
  }
}
