import { Controller } from 'stimulus';

import Swal from 'sweetalert2';

export default class extends Controller {
  static targets = ['unlikeClass', 'likeClass', 'likeAction'];

  static values = {
    url: String,
    hideCard: Boolean,
    doctorId: String,
  };

  like(e) {
    const event = e;
    const url = this.urlValue;

    fetch(url, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': this.getMetaValue('csrf-token'),
      },
    })
      .then((response) => response.text())
      .then((data) => {
        const responseData = JSON.parse(data);
        if (responseData.success == true) {
          this.likeClassTarget.classList.remove('text-gray-600');
          this.likeClassTarget.classList.add('text-green-500');
          this.likeActionTarget.dataset.action = 'click->likes#unlike';
        }
      });
  }

  unlike() {
    const url = this.urlValue;
    const hideCard = this.hideCardValue;
    const doctorId = this.doctorIdValue;

    fetch(url, {
      method: 'DELETE',
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': this.getMetaValue('csrf-token'),
      },
    })
      .then((response) => response.text())
      .then((data) => {
        const responseData = JSON.parse(data);
        if (responseData.success == true) {
          this.likeClassTarget.classList.add('text-gray-600');
          this.likeClassTarget.classList.remove('text-green-500');
          this.likeActionTarget.dataset.action = 'click->likes#like';

          if (hideCard == true) {
            var element = document.getElementById(`doc-${doctorId}`);
            element.classList.add(`hidden`);
          }
        }
      });
  }

  throughAlert() {
    Swal.fire({
      text:
        'Please create an account and sign in in-order to use this function',
      showCancelButton: true,
      confirmButtonText: `Sign Up`,
      confirmButtonColor: '#e7ab00',
      cancelButtonColor: '#191932',
      cancelButtonText: `Ok`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        window.location.href = `/users/sign_up`;
      }
    });
  }

  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`);
    return element.getAttribute('content');
  }
}
