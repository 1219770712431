/* eslint-disable import/no-webpack-loader-syntax */
import { Controller } from 'stimulus';
import mapboxgl from '!mapbox-gl';

export default class extends Controller {
  static values = {
    practiceStyle: String,
  };

  connect() {
    mapboxgl.accessToken =
      'pk.eyJ1IjoiZmluZG15ZGlyZWN0ZG9jdG9yIiwiYSI6ImNsdmdja3JkejB1eTkyam5xMWs2bDZjejEifQ.RdmJuF1OjqVmTnlBr7nTDA';
    this.initializeMap([-94.5, 40], 5);
    this.setupSearchAutocomplete();
  }

  initializeMap(coordinates, zoom) {
    this.map = new mapboxgl.Map({
      container: 'mapbox',
      style: 'mapbox://styles/findmydirectdoctor/clxum8pm8049u01qm077n9lpi',
      center: coordinates,
      zoom: zoom,
    });
    const searchDiv = document.getElementById('mapbox-search');
    const spinner = document.getElementById('spinner');
    this.map.on('load', () => {
      this.map.addControl(new mapboxgl.NavigationControl());
      searchDiv.style.display = 'block';

      this.fetchLocations();

      // Set up the dropdown filter event listener
      // if (document.getElementById('stateDropdown')) {
      //   document
      //     .getElementById('stateDropdown')
      //     .addEventListener('change', (e) => {
      //       const selectedState = e.target.value;
      //       document.getElementById('mapbox-input').value = '';
      //       spinner.style.display = 'block';
      //       this.fetchLocations(selectedState);
      //     });
      // }
    });
  }

  fetchLocations(coordinates = '') {
    fetch(
      `/mapbox/doctors/${this.practiceStyleValue}/?coordinates=${coordinates}`
    )
      .then((response) => response.json())
      .then((data) => {
        this.locations = data.locations;
        this.state = data.state;
        this.location_string = data.location_string;
        if (coordinates == '') {
          document.getElementById('mapbox-input').value = this.location_string;
        }
        this.updateMap(this.locations);
        // document.getElementById('stateDropdown').value = this.state;
      })
      .catch((error) => {
        console.error('Error fetching locations:', error);
      });
  }

  updateMap(locations) {
    // Clear existing markers
    if (this.markers) {
      this.markers.forEach((marker) => marker.remove());
    }

    this.markers = [];

    const bounds = new mapboxgl.LngLatBounds();
    const spinner = document.getElementById('spinner');

    locations.forEach((location) => {
      const el = document.createElement('div');
      el.className = `mapboxgl-marker marker ${this.practiceStyleValue}`;
      el.addEventListener('click', () => {
        this.openSidebar(location);
      });

      if (location.coordinates && location.coordinates.length === 2) {
        const marker = new mapboxgl.Marker(el)
          .setLngLat(location.coordinates)
          .addTo(this.map);
        this.markers.push(marker);
        bounds.extend(location.coordinates);
      } else {
        console.error(`Invalid coordinates for location: ${location.name}`);
      }
    });

    // Adjust map to center on the first marker
    if (locations.length > 0) {
      const firstLocation = locations[0];
      var center = [-94.5, 40];
      var zoom = 5;
      if (this.state) {
        center = firstLocation.coordinates;
        zoom = 7;
      }
      this.map.flyTo({
        center: center,
        zoom: zoom,
        essential: true,
      });
      spinner.style.display = 'none';
    }
  }

  search() {
    const searchQuery = document.getElementById('mapbox-input').value;
    fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
        searchQuery
      )}.json?access_token=${mapboxgl.accessToken}&country=US`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.features.length > 0) {
          const coordinates = data.features[0].center;
          this.map.flyTo({ center: coordinates, zoom: 8 });
        } else {
          alert('No results found for the search query.');
        }
      })
      .catch((error) => {
        console.error('Error fetching geocoding data:', error);
        alert(
          'An error occurred while fetching geocoding data. Please try again later.'
        );
      });
  }

  setupSearchAutocomplete() {
    const input = document.getElementById('mapbox-input');
    const suggestionsContainer = document.getElementById('suggestions');
    const spinner = document.getElementById('spinner');
    let debounceTimeout;

    input.addEventListener('input', () => {
      clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(() => {
        const query = input.value.trim();
        if (query.length > 0) {
          this.fetchSuggestions(query);
        } else {
          suggestionsContainer.innerHTML = '';
        }
      }, 300);
    });

    suggestionsContainer.addEventListener('click', (e) => {
      if (e.target.classList.contains('suggestion-item')) {
        const coordinates = e.target.dataset.coordinates.split(',').map(Number);
        input.value = e.target.textContent;
        if (this.practiceStyleValue == 'cm') {
          spinner.style.display = 'block';
          this.fetchLocations(coordinates);
        } else {
          this.map.flyTo({ center: coordinates, zoom: 8 });
        }
        suggestionsContainer.innerHTML = '';
      }
    });
  }

  fetchSuggestions(query) {
    fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
        query
      )}.json?access_token=${mapboxgl.accessToken}&country=US`
    )
      .then((response) => response.json())
      .then((data) => {
        const suggestionsContainer = document.getElementById('suggestions');
        suggestionsContainer.innerHTML = '';
        data.features.forEach((feature) => {
          const item = document.createElement('div');
          item.className = 'suggestion-item';
          item.textContent = feature.place_name;
          item.dataset.coordinates = feature.center;
          suggestionsContainer.appendChild(item);
        });
      })
      .catch((error) => {
        console.error('Error fetching suggestions:', error);
      });
  }

  openSidebar(location) {
    const sidebar = document.getElementById('sidebar');
    sidebar.classList.add('map-sidebar--open');

    fetch(`/mapbox/doctors/show/${location.id}`)
      .then((response) => response.json())
      .then((data) => {
        document.querySelector('#sidebar').innerHTML = data.partial_html;
      })
      .catch((error) => {
        // console.error('Error fetching data:', error);
      });
  }
}
